// 单图片上传

<template>
  <div class="upload">
    <el-upload
      ref="uploadVideo"
      :headers="headers"
      :action="action"
      name="attach"
      :accept="accept"
      :limit="1"
      :show-file-list="false"
      class="uploader-img"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
    >
      <el-progress v-if="status === 'uploading'" type="circle" :percentage="progress"></el-progress>

      <div v-if="url && status === 'success'" class="img-box" :style="`height: ${autoHeight ? 'auto' : '150px'}`">
        <video class="img" :src="url"></video>
        <div class="mask">
          <i class="el-icon-edit" slot="trigger"></i>
          <i class="el-icon-delete" @click.stop="handleDelete"></i>
        </div>
      </div>

      <i v-if="!url && status === 'success'" class="el-icon-plus uploader-img-icon"></i>

      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    url: { type: String, default: '' },
    accept: { type: String, default: 'video/*' },
    size: { type: [Number, String], default: 300 },
    tip: { type: String, default: '' },
    autoHeight: { type: Boolean, default: false } // 自动高度
  },

  data() {
    return {
      headers: { Authorization: localStorage.getItem('token') },
      action: process.env.VUE_APP_BASE_API + '/serve-user/app-api/v1/upload',

      status: 'success',
      progress: 0,

      duration: null
    }
  },

  computed: {},

  watch: {
    url: {
      handler(value) {},
      immediate: true
      // deep: true
    }
  },

  created() {},

  methods: {
    // 上传前触发
    beforeUpload(file) {
      const isLtSize = file.size / 1024 / 1024 < this.size

      if (!isLtSize) {
        this.$message.error('上传文件的大小不能超过 ' + this.size + 'MB!')
      }

      // 获取视频时长
      const url = URL.createObjectURL(file)
      const audioElement = new Audio(url)
      audioElement.addEventListener('loadedmetadata', () => {
        this.duration = Math.round(audioElement.duration)
      })

      return isLtSize
    },

    // 文件上传时的钩子
    onProgress(event, file, fileList) {
      this.status = 'uploading'
      this.progress = parseInt(event.percent)
    },

    // 上传成功时触发
    onSuccess(res, file) {
      this.status = 'success'
      this.$emit('update:url', res.data[0])
      file.duration = this.duration
      this.$emit('onSuccess', res, file)
      this.$refs.uploadVideo.clearFiles() // 上传完成后清空已上传的文件列表
    },

    // 删除文件
    handleDelete() {
      this.$emit('update:url', '')
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  line-height: normal;

  /deep/ .uploader-img {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      overflow: hidden;
      &:hover {
        border-color: #409eff;
      }

      .el-progress {
        margin: 12px;
      }

      .img-box {
        position: relative;
        width: 150px;
        // height: 150px;
        &:hover .mask {
          display: flex;
        }

        .img {
          width: 100%;
          height: 100%;
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.5);
          font-size: 20px;
          cursor: default;

          i {
            margin: 10px;
            cursor: pointer;
          }
        }
      }

      .uploader-img-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
      }
    }

    .el-upload__tip {
    }
  }
}
</style>
